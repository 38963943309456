// Not the cleanest way of doing this, will improve soon

import { DISABLE_PREMIUM, WHITELISTED_CONTRACTS, DISCOUNT_CONTRACTS } from './config';
import catcABI from '../abi/catc.abi.json';

// Should have premium until 1.1.2023
const loopyDonutsTeam = [
  '0x3412A4540Bf2B4B597a7D96497C3052632e4f95B', // Astrid | Team
  '0xA1a3524e1bD29F71d11E787d36BAEBB91bfc20Da', // Mariana | Team
  '0x4b26765af0455774fe3ca31a24535010f58f0343', // Lusidream | Team
  '0xd9ea5B738C8733eE799992CF065EbF45A08156a4', // Chaz | Team
  '0xb40f9efa6592b6b3b5a3d8cde1fb91c56211e252', // Liz (guiltykitchen.eth) | Team
  '0x8AaFE92FcA2c3E93dFB52172EB110fF9dAbDC395', // HeirofSlytherin | Team
  '0xA9a3e79dB5288dD5fCfE2041F9B10740137Be314', // Pascal Costa | Team
  '0x94E2B96e105f50f63F42B831646A61CAD2bE44c5', // robmthespian | Team
  '0x1EEd4a1912F04F5F4C16bf252ff58eAe44083CD0', // Aldenchng | Giveaway winner
  '0x7144995046764d4cDfEF1a9E92aDE92d932ac376', // IAmMattBeezy | Giveaway winner
  '0x4993B35f56799fE504392d0bb1D57dfc3D73DA70', // NiftyCrypt | Giveaway winner
];

// Should have premium until 1.1.2023
const curiousAddysWinners = [
  '0x412FB02f7e59Ce05F7647F1136ba1eb80eE2a741 ',
  '0x815e6fFBd9B593e2E28F12d8CC19791C70E2091E',
];

const other = [
  '0xD514511E297d40A49F07A312c5342C9346A68b00', // Naor B
  '0xf5e20c261dFBba62EdEf37116cbf86cDBA6220Cb', // Tamir G
  '0x90B6e718268EAcD742e4E90C75Bec9bBa06F3066', // Squishy Squad founder
  '0xBcc48e02eD5b97CA855F282d1FfDef268Bb88A83', // L
  '0x8C9FcCEd4c9ec98Ced2EeC251E905E48Dbfeb2C5', // Anu
  '0x46b421bA99f0c3135c925a5D39fCA5d7830f2d82', // Ilan S
  '0x1b94cB84a329624E8885E872e4540986deDd9aAc', // pollork.eth | Otomotors giveaway winner | until 10/1/2023
  '0x1cf61d84656d9392D4848252F40A3821839D6dB9', // paelsworld | Otomotors giveaway winner | until 10/1/2023
  '0xb6e1eAF5EDBDd034FCf9B1F72919fb87e8f237a0', // Dracoverse trial
  '0x677257F094e0cDcA58BeAD00f403D7d1623272b8', // Jonathon T
  '0x0a3E0c2f8382995E1cF60db0B82899a2F8A9CfF7', // @BennyJH82 | Bored Bigfoot Billionars giveaway winner | until 18/1/2023
  '0xaD26E6F7598679e6e1F04B3fd09ef7Dd156Bcc89', // @oksarandri | Bored Bigfoot Billionars giveaway winner | until 18/1/2023
  '0x53c5E348A32C2fcd38180e45EBD841376f59AaeB', // David Binance
];

const whitelist = loopyDonutsTeam.concat(curiousAddysWinners).concat(other);

// Smart contract of projects that all of their holders are whitelisted for premium. Should have premium until 1.1.23,
export const whitelistedCollections = DISABLE_PREMIUM
  ? []
  : WHITELISTED_CONTRACTS.map(address => {
      return { address, abi: catcABI };
    });

// address of projects that all of their holders are entitled for a 40% discount on monthly or 6 month premium
export const discountCollections = DISABLE_PREMIUM
  ? []
  : DISCOUNT_CONTRACTS.map(address => {
      return { address, abi: catcABI };
    });

export const isWhitelistedManually = (address?: string): boolean => {
  return !!(
    DISABLE_PREMIUM ||
    (address &&
      whitelist.find(whitelistAddress => whitelistAddress.toLowerCase() === address.toLowerCase()))
  );
};
