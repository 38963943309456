import React, { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
// import { HotKeys } from 'react-hotkeys';
import Web3Modal from 'web3modal';
import WalletLink from 'walletlink';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ExternalProvider } from '@ethersproject/providers';
import { useLocalStorage } from 'react-use';
import PURCHASE_CONTRACT from '../abi/Exodia.json';
import { Button } from '@components/Button';
import { Modal, useModal } from '@components/Modal';
import { SubHeading } from '@components/Typography';
import { useNotification } from '@components/Notification';
import { msToSeconds, secondsToDays, isMobileDevice, prettyError } from './general';
import {
  INFURA_ID,
  NETWORK_NAME,
  PURCHASE_CONTRACT_ADDRESS,
  SUBSCRIPTION_ADDRESS,
  ONE_MONTH_SUBSCRIPTION_PRICE,
  SIX_MONTHS_SUBSCRIPTION_PRICE,
  PARTNERS_DISCOUNT_PRICE,
} from './config';
import { whitelistedCollections, discountCollections } from './whitelist';
import { signMessage, verifyMessage } from './web3';
import { updateSignature } from './api';

const LIFETIME_TOKEN_ID = 1;

const ONE_MONTH_PREMIUM_DAYS = 30;
const SIX_MONTHS_PREMIUM_DAYS = 183;
const ONE_MONTH_SUBSCRIPTION_PRICE_DISCOUNT =
  ONE_MONTH_SUBSCRIPTION_PRICE * (1 - PARTNERS_DISCOUNT_PRICE);
const SIX_MONTHS_SUBSCRIPTION_PRICE_DISCOUNT =
  SIX_MONTHS_SUBSCRIPTION_PRICE * (1 - PARTNERS_DISCOUNT_PRICE);

const networks: { [key: string]: string } = {
  mainnet: '0x1', // 1
  ropsten: '0x3', // 3
  rinkeby: '0x4', // 4
  local: '0x539', // 1337
};
const chainId = networks[NETWORK_NAME];

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
  'custom-walletlink': {
    display: {
      logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
      name: 'Coinbase',
      description: 'Connect to Coinbase Wallet (not Coinbase App)',
    },
    options: {
      appName: 'Exodia', // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      chainId,
    },
    package: WalletLink,
    connector: async (_: any, options: any) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({ appName });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
};

export type UserDetails = { address?: string; signature?: string };

interface Context {
  address?: string;
  user: UserDetails;
  lifetimeTokenCount?: number;
  hasPremium?: boolean;
  subscriptionStartDate?: number;
  partnerContractAddress?: string;
  onConnectWallet: () => Promise<void>;
  checkIfWalletConnected: () => Promise<void>;
  onLoadLifetimeCount: () => Promise<boolean>;
  onLoadAccess: () => Promise<boolean>;
  onPurchase: (price: number) => Promise<void>;
  onRefund: () => Promise<void>;
}

const defaultContextValue: Context = {
  address: undefined,
  user: {},
  lifetimeTokenCount: undefined,
  onConnectWallet: async () => {},
  checkIfWalletConnected: async () => {},
  onLoadLifetimeCount: async () => false,
  onLoadAccess: async () => false,
  onPurchase: async () => {},
  onRefund: async () => {},
};

export const Web3Context = React.createContext<Context>(defaultContextValue);

export const useWeb3Context = () => React.useContext(Web3Context);

export const Web3Provider: React.FC<{}> = ({ children }) => {
  const [address, setAddress] = useState<string | undefined>();

  // const [signedAddress, setSignedAddress] = React.useState<string | undefined>();
  const [user, setUser] = React.useState<UserDetails>({});
  const [lifetimeTokenCount, setLifetimeTokenCount] = React.useState<number | undefined>(undefined);
  // this can be true if either lifetime token count > 0 or if the user has whitelisted token (e.g addys)
  const [hasPremium, setHasPremium] = React.useState(false);
  const [partnerContractAddress, setPartnerContractAddress] = React.useState('');
  const [subscriptionStartDate, setSubscriptionStartDate] = React.useState<number | undefined>(
    undefined
  );

  const [localStorageSignature, setLocalStorageSignature] = useLocalStorage<string>('signature');
  const [localStorageAddress, setLocalStorageAddress] = useLocalStorage<string>('address');
  const setUserDetails = useCallback(
    ({ address, signature }: UserDetails) => {
      setUser({ address, signature });
      setLocalStorageSignature(signature);
      setLocalStorageAddress(address);
      updateSignature({ address, signature });
    },
    [setLocalStorageAddress, setLocalStorageSignature]
  );

  const { isModalOpen, openModal, closeModal } = useModal();
  const [errorModalMessage, setErrorModalMessage] = React.useState('');

  const { showSuccessNotification } = useNotification();

  const isMobile = isMobileDevice();

  const closeErrorModal = React.useCallback(() => setErrorModalMessage(''), []);

  const onConnectWallet = React.useCallback(async () => {
    const walletResponse = await connectWallet();
    setAddress(walletResponse?.address);

    if (!walletResponse?.address) openModal();
  }, [openModal]);

  const checkIfWalletConnected = useCallback(async () => {
    const connectedAddress = await getConnectedAdress();
    if (connectedAddress.address) {
      setAddress(connectedAddress.address);
    }
  }, []);

  const userHasActiveSubscription = async (userAddress: string): Promise<boolean> => {
    const etherscanProvider = new ethers.providers.EtherscanProvider();
    const history = await etherscanProvider.getHistory(userAddress!);

    const oneMonthSubscriptionTxs = history
      .filter(
        (tx: any) =>
          tx.from &&
          tx.from.toLowerCase() === userAddress?.toLowerCase() &&
          tx.to &&
          tx.to.toLowerCase() === SUBSCRIPTION_ADDRESS.toLowerCase() &&
          tx.value &&
          Number(ethers.utils.formatEther(tx.value)) ==
            (ONE_MONTH_SUBSCRIPTION_PRICE || ONE_MONTH_SUBSCRIPTION_PRICE_DISCOUNT)
      )
      .sort((a: any, b: any) => b.timestamp - a.timestamp);

    const sixMonthsSubscriptionTxs = history
      .filter(
        (tx: any) =>
          tx.from &&
          tx.from.toLowerCase() === userAddress?.toLowerCase() &&
          tx.to &&
          tx.to.toLowerCase() === SUBSCRIPTION_ADDRESS.toLowerCase() &&
          tx.value &&
          Number(ethers.utils.formatEther(tx.value)) ==
            (SIX_MONTHS_SUBSCRIPTION_PRICE || SIX_MONTHS_SUBSCRIPTION_PRICE_DISCOUNT)
      )
      .sort((a: any, b: any) => b.timestamp - a.timestamp);

    const txsToSubscriptionAddress = sixMonthsSubscriptionTxs.concat(oneMonthSubscriptionTxs);
    const latestSubscriptionTx = txsToSubscriptionAddress[0];
    const subscriptionDays =
      latestSubscriptionTx === sixMonthsSubscriptionTxs[0]
        ? SIX_MONTHS_PREMIUM_DAYS
        : ONE_MONTH_PREMIUM_DAYS;

    const hasActiveSubscription =
      latestSubscriptionTx &&
      secondsToDays(msToSeconds(Date.now()) - (latestSubscriptionTx.timestamp ?? 0)) <
        subscriptionDays;

    if (hasActiveSubscription) {
      setSubscriptionStartDate(latestSubscriptionTx.timestamp);
    }

    return hasActiveSubscription;
  };

  const onPurchase = React.useCallback(
    async (price: number) => {
      await onConnectWallet();
      const { signer, discountContracts } = await connectWeb3();
      const userAddress = await signer?.getAddress();

      let holdsPartnersNFT = false;

      for (const discountContract of discountContracts || []) {
        const tokensThatProvidesDiscount = await discountContract.balanceOf(userAddress);
        if (tokensThatProvidesDiscount.toNumber() > 0) {
          setPartnerContractAddress(discountContract.address);
          holdsPartnersNFT = true;
          break;
        }
      }

      // This line should be removed when user will be connected from the start instead of needed to connect again
      const finalPrice = !holdsPartnersNFT ? price : price * (1 - PARTNERS_DISCOUNT_PRICE);

      try {
        const transaction = await signer?.sendTransaction({
          to: SUBSCRIPTION_ADDRESS,
          value: ethers.utils.parseUnits(finalPrice.toString(), 'ether').toHexString(),
        });

        showSuccessNotification({
          message: 'Pending',
          description:
            'The transaction is processing. This can take a while depending on how congested the blockchain is.',
        });

        await transaction?.wait();

        showSuccessNotification({
          message: 'Success',
          description: `Congrats! You've been upgraded to premium! Don't forget to join our Discord chat too!`,
        });
      } catch (error: any) {
        console.log(prettyError(error));

        if (error.code === 'INSUFFICIENT_FUNDS')
          setErrorModalMessage(`You do not have sufficient funds to complete the purchase.`);
      }
    },
    [onConnectWallet, showSuccessNotification]
  );

  // NFT minting
  // const onPurchase = React.useCallback(
  //   async (card?: { price: number; tokenId: number; amount?: number }) => {
  //     const walletResponse = await onConnectWallet();
  //     const { contract } = await connectWeb3();

  //     if (!contract) return onConnectWallet();

  //     try {
  //       const tokenId = card?.tokenId || LIFETIME_TOKEN_ID;
  //       const nftPrice = card?.price || 0.15;
  //       const amount = card?.amount || 1;

  //       const price = ethers.utils.parseUnits((nftPrice * amount).toString(), 'ether');
  //       const transaction = await contract.buy(tokenId, amount, { value: price });

  //       showSuccessNotification({
  //         message: 'Pending',
  //         description:
  //           'The transaction is processing. This can take a while depending on how congested the blockchain is.',
  //       });

  //       await transaction.wait();

  //       showSuccessNotification({
  //         message: 'Success',
  //         description: `Congrats! You've been upgraded to premium! Don't forget to join our premium Discord chat too!`,
  //       });
  //     } catch (error: any) {
  //       console.log(prettyError(error));

  //       if (error.code === 'INSUFFICIENT_FUNDS')
  //         setErrorModalMessage(`You do not have sufficient funds to complete the purchase.`);
  //       else setErrorModalMessage(`There was an error completing the purchase :(`);
  //     }
  //   },
  //   [onConnectWallet, showSuccessNotification]
  // );

  const onLoadLifetimeCount = React.useCallback(async () => {
    await onConnectWallet();
    const { contract, signer, whitelistedContracts } = await connectWeb3();

    if (!contract) {
      onConnectWallet();
      return false;
    }
    const userAddress = await signer?.getAddress();

    const resLifetime = await contract.balanceOf(userAddress, LIFETIME_TOKEN_ID);
    const count = resLifetime.toNumber();

    setLifetimeTokenCount(count);

    if (count) {
      setHasPremium(true);
      return true;
    }

    const hasActiveSubscription = userAddress && (await userHasActiveSubscription(userAddress));
    if (hasActiveSubscription) {
      setHasPremium(true);
      return true;
    }

    let hasPremium = false;

    for (const whitelistedContract of whitelistedContracts || []) {
      const whitelistedTokens = await whitelistedContract.balanceOf(userAddress);
      if (whitelistedTokens.toNumber() > 0) {
        hasPremium = true;
        setHasPremium(true);
        setPartnerContractAddress(whitelistedContract.address);
        break;
      }
    }

    return hasPremium;
  }, [onConnectWallet]);

  const onLoadAccess = React.useCallback(async () => {
    const walletResponse = await onConnectWallet();
    const { contract, signer } = await connectWeb3();

    if (!contract || !signer) {
      await onConnectWallet();
      return false;
    }

    let signature: string;
    let address: string;

    if (localStorageSignature && localStorageAddress) {
      signature = localStorageSignature;
      address = localStorageAddress;
    } else {
      const result = await signMessage({ signer });
      signature = result.signature;
      address = result.address;
    }
    setUserDetails({ address, signature });

    const isSigned = verifyMessage({ address, signature });

    if (!address) return false;

    const balance = await contract.balanceOf(address, LIFETIME_TOKEN_ID);
    const tokens = +balance.toString();

    setLifetimeTokenCount(tokens);
    setHasPremium(tokens > 0);

    return isSigned && tokens > 0;
  }, [localStorageAddress, localStorageSignature, onConnectWallet, setUserDetails]);

  const onRefund = React.useCallback(async () => {
    const walletResponse = await onConnectWallet();
    const { contract } = await connectWeb3();

    if (!contract) return onConnectWallet();

    try {
      const transaction = await contract.refund(LIFETIME_TOKEN_ID, 1);

      showSuccessNotification({
        message: 'Pending',
        description:
          'The transaction is processing. This can take a while depending on how congested the blockchain is.',
      });

      await transaction.wait();

      showSuccessNotification({
        message: 'Success',
        description: `Your eth has been refunded!`,
      });

      onLoadLifetimeCount();
    } catch (error: any) {
      console.log(prettyError(error));

      setErrorModalMessage(
        `There was an error completing the refund 😔\n\n${error.error?.message || ''}`
      );
    }
  }, [onConnectWallet, onLoadLifetimeCount, showSuccessNotification]);

  // TODO this isn't set up well at all (and isn't secure)
  // someone could buy the token, and then sell it on for example, and would still have access
  const onLoadLocalStorageAccess = React.useCallback(async () => {
    if (localStorageAddress && localStorageSignature) {
      const signed = verifyMessage({
        address: localStorageAddress,
        signature: localStorageSignature,
      });
      if (signed) setUser({ address: localStorageAddress, signature: localStorageSignature });
    }
  }, [localStorageAddress, localStorageSignature]);

  React.useEffect(() => {
    onLoadLocalStorageAccess();
  }, [onLoadLocalStorageAccess]);

  // on disconnect from metamask
  useEffect(() => {
    const onAccountChanged = (accounts: Array<string>) => {
      if (accounts.length === 0) {
        setAddress(undefined);
      }
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', onAccountChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', onAccountChanged);
      }
    };
  }, []);

  return (
    // <HotKeys
    //   keyMap={{ ESCAPE: 'escape' }}
    //   handlers={{
    //     ESCAPE: () => {
    //       closeModal();
    //       closeErrorModal();
    //     },
    //   }}
    // >
    <Web3Context.Provider
      value={{
        address,
        user,
        lifetimeTokenCount,
        hasPremium,
        subscriptionStartDate,
        partnerContractAddress,
        onConnectWallet,
        checkIfWalletConnected,
        onPurchase,
        onLoadLifetimeCount,
        onLoadAccess,
        onRefund,
      }}
    >
      {children}
      {isModalOpen && (
        <Modal hideModal={closeModal}>
          <div className="max-w-5xl p-6 flex flex-col items-center">
            <div className="text-lg">Please connect your wallet.</div>
            <div className="mt-8 w-64">
              <Button
                size="lg"
                color="gradient-v2"
                asLink
                block
                rounded
                href={
                  isMobile
                    ? 'https://metamask.app.link/dapp/exodia.io'
                    : 'https://metamask.io/download.html'
                }
                target="_blank"
              >
                {isMobile ? 'Launch MetaMask' : `Install MetaMask`}
              </Button>
              {/* doesn't do anything / confusing. removed */}
              {/* <div className="mt-2">
                <Button size="lg" color="primary" block onClick={closeModal}>
                  Use different wallet
                </Button>
              </div> */}
            </div>
          </div>
        </Modal>
      )}

      {errorModalMessage && (
        <Modal hideModal={closeErrorModal}>
          <div className="max-w-5xl p-6 flex flex-col items-center">
            <SubHeading>Error</SubHeading>
            <div className="text-lg mt-6 whitespace-pre-line">{errorModalMessage}</div>
          </div>
        </Modal>
      )}
    </Web3Context.Provider>
    // </HotKeys>
  );
};

const connectWallet = async (): Promise<{
  address: string | undefined;
}> => {
  if (window.ethereum) {
    try {
      const addresses = await window.ethereum.request({ method: 'eth_requestAccounts' });

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      });

      return { address: addresses[0] };
    } catch (err) {}
  }
  return { address: undefined };
};

const getConnectedAdress = async (): Promise<{
  address: string | undefined;
}> => {
  if (window.ethereum) {
    try {
      const addresses = await window.ethereum.request({ method: 'eth_accounts' });

      // await window.ethereum.request({
      //   method: 'wallet_switchEthereumChain',
      //   params: [{ chainId }],
      // });

      return { address: addresses[0] };
    } catch (err) {}
  }
  return { address: undefined };
};

async function connectWeb3(): Promise<{
  contract?: ethers.Contract;
  signer?: ethers.providers.JsonRpcSigner;
  whitelistedContracts?: ethers.Contract[];
  discountContracts?: ethers.Contract[];
}> {
  const { signer } = await getProviderAndSigner();
  const contract = new ethers.Contract(PURCHASE_CONTRACT_ADDRESS, PURCHASE_CONTRACT.abi, signer);

  const whitelistedContracts = whitelistedCollections.map(
    whitelistedCollection =>
      new ethers.Contract(whitelistedCollection.address, whitelistedCollection.abi, signer)
  );

  const discountContracts = discountCollections.map(
    discountCollection =>
      new ethers.Contract(discountCollection.address, discountCollection.abi, signer)
  );

  return { contract, signer, whitelistedContracts, discountContracts };
}

export async function getProviderAndSigner() {
  if (typeof window === 'undefined') return {};

  const ethereum: ExternalProvider | undefined = window.ethereum;
  await connectWallet();

  // we use web3 modal as backup to regular metamask
  const provider = new ethers.providers.Web3Provider(
    ethereum ||
      (await new Web3Modal({
        network: NETWORK_NAME,
        cacheProvider: true,
        providerOptions,
      }).connect())
  );
  return { provider, signer: provider?.getSigner() } as const;
}

export function useLatestBlock() {
  const [block, setBlock] = useState<number>();

  useEffect(() => {
    const getBlock = async () => {
      const b = await ethers.providers.getDefaultProvider().getBlockNumber();
      setBlock(b);
    };

    getBlock();
  }, []);

  return block;
}
