import { useEffect } from 'react';
import amplitude from 'amplitude-js';
import { AMPLITUDE_API_KEY } from './config';

export const useAmplitude = () => {
  useEffect(() => {
    if (typeof window === 'undefined' || !AMPLITUDE_API_KEY) return;
    amplitude.getInstance().init(AMPLITUDE_API_KEY);
  }, []);
};

export const useLogPage = (page: string, data?: any) => {
  useEffect(() => {
    logPage(page, data);
  }, [data, page]);
};

export const identifyUser = (address: string) => {
  if (typeof window === 'undefined' || !AMPLITUDE_API_KEY) return;

  new amplitude.Identify().setOnce('$address', address);
};

const logEvent = (event: string, data?: any) => {
  if (typeof window === 'undefined' || !AMPLITUDE_API_KEY) return;

  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.logEvent(event, data);
};

// there are ways to automatically doing this using router but they don't really do what we want
function logPage(page: string, data?: any) {
  logEvent(`View ${page}`, data);
}

export function logConnectAddress() {
  logEvent('Connect Address');
}

export function logOpenNewAlertModal() {
  logEvent('Open New Alert Modal');
}

export function logCloseNewAlertModal() {
  logEvent('Close New Alert Modal');
}

export function logCreateNewAlert(data: any) {
  logEvent('Create New Alert', data);
}
