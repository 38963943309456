function getBaseUrl(): string {
  if (!process.env.NEXT_PUBLIC_VERCEL_URL) return 'http://localhost:3000';

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') return `https://exodia.io`;

  return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
}
export const BASE_URL = getBaseUrl();

function getBackendUrl(): string {
  if (!process.env.NEXT_PUBLIC_BACKEND_URL) return 'http://localhost:3001';

  return process.env.NEXT_PUBLIC_BACKEND_URL;
}
export const BACKEND_URL = getBackendUrl();

export const DISABLE_PREMIUM = process.env.NEXT_PUBLIC_DISABLE_PREMIUM === 'true';
export const PURCHASE_CONTRACT_ADDRESS =
  process.env.NEXT_PUBLIC_PURCHASE_CONTRACT_ADDRESS || '0x3cd1f1c550ed359ae5cf6c3ed0e5c50c04eb8318';
export const INFURA_ID = process.env.NEXT_PUBLIC_INFURA_ID || '9171b6990eb5442c9ac0224115df03bd';
export const WHITELISTED_CONTRACTS = process.env.NEXT_PUBLIC_WHITELISTED_CONTRACTS
  ? process.env.NEXT_PUBLIC_WHITELISTED_CONTRACTS.replace("'", '').split(',')
  : [];
export const DISCOUNT_CONTRACTS = process.env.NEXT_PUBLIC_DISCOUNT_CONTRACTS
  ? process.env.NEXT_PUBLIC_DISCOUNT_CONTRACTS.replace("'", '').split(',')
  : [];
export const NETWORK_NAME = process.env.NEXT_PUBLIC_NETWORK_NAME || 'mainnet';
export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
export const WRITE_DATABASE_URL = process.env.WRITE_DATABASE_URL;

export const PARTNERS_DISCOUNT_PRICE = Number(
  process.env.NEXT_PUBLIC_PARTNERS_DISCOUNT_PRICE || 0.4
);
export const SUBSCRIPTION_ADDRESS =
  process.env.NEXT_PUBLIC_SUBSCRIPTION_ADDRESS || '0x76CB3CF8EE2B2dD67832e6C78406Ab953Faba812';

export const ONE_MONTH_SUBSCRIPTION_PRICE = Number(
  process.env.NEXT_PUBLIC_ONE_MONTH_SUBSCIPTION_PRICE || 0.08
);
export const SIX_MONTHS_SUBSCRIPTION_PRICE = Number(
  process.env.NEXT_PUBLIC_ONE_MONTHS_SUBSCIPTION_PRICE || 0.4
);
